import { Theme } from '@wix/forms-common'
import { fetchRetry } from '../../../utils/fetch-utils'
import {
  ADD_PANEL_COMPONENTS_PRESET_STATIC_URL,
  THEMES_COMPONENTS_STATIC_URL,
} from '../../../constants/resources'
import _ from 'lodash'

const themes: { [key: string]: Promise<any> } = {}
const addPanelThemes: { [key: string]: any } = {}

export const addTheme = (themeName: string, themeValue) => {
  themes[themeName] = themeValue
}

export const addComponentTheme = (componentName: string, themeName: string, themeValue) => {
  addPanelThemes[`${componentName}-${themeName}`] = themeValue
}

export const fetchFormsThemes = (ravenInstance) => {
  _.values(Theme).forEach(async (theme) => {
    const response = await fetchRetry(ravenInstance)(
      `${THEMES_COMPONENTS_STATIC_URL}/${theme}.json`,
    )
    addTheme(theme, response.json())
  })
}

export const fetchAddPanelPresets = (ravenInstance) => {
  [
    Theme.PRESET01,
    Theme.PRESET02,
    Theme.PRESET03,
    Theme.PRESET04,
    Theme.PRESET05,
    Theme.PRESET06,
  ].forEach(async (theme) => {
    try {
      ['complexAddress', 'complexPhone'].forEach( async componentName => {
        const response = await fetchRetry(ravenInstance)(
          `${ADD_PANEL_COMPONENTS_PRESET_STATIC_URL}/${componentName}/${theme}.json`,
        )
        addComponentTheme(componentName, theme, response.json())
      });
    } catch {
      return
    }
  })
}

const extractThemeFields = (theme) => {
  const stylesByRole = _.get(theme, 'style', {})
  const propsByRole = _.get(theme, 'props', {})
  const dataByRole = _.get(theme, 'data', {})

  return { stylesByRole, propsByRole, dataByRole }
}

export const getTheme = async (themeName: string) => {
  const theme = await themes[themeName]
  return extractThemeFields(theme)
}

export const getComponentTheme = async (themeName: string) => {
  const theme = await addPanelThemes[themeName]
  return extractThemeFields(theme)
}
