import CoreApi from '../core/core-api'
import { getAppManifest as coreGetAppManifest } from '../core/manifests/app-manifest'
import { editorAppMetaData } from './editor-app'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { handleError } from './monitoring'

export const getAppManifest: EditorPlatformApp['getAppManifest'] = async (_options, _editorSdk) => {
  const api: CoreApi = await editorAppMetaData.getCoreApi()

  try {
    if (api.isADI()) {
      return {}
    }

    api.interactionStarted('get-app-manifest')

    const isResponsive = api.isResponsive()

    if (!editorAppMetaData.appManifest) {
      let isTopPremium = false

      try {
        const currentAscendPlan = await api.premium.getCurrentAscendPlan()
        isTopPremium = currentAscendPlan.isTopPremium
      } catch (ex) {}

      const withWidgetDesign = api.isEditorExperimentEnabled('se_widgetDesign')

      editorAppMetaData.appManifest = coreGetAppManifest({
        isTopPremium,
        isResponsive,
        withWidgetDesign,
      })
    }

    try {
      const filteredManifest = await api.filterAppManifestV2(editorAppMetaData.appManifest)

      // console.log('original', new Blob([JSON.stringify(editorAppMetaData.appManifest, null, 2)]).size / 1024, 'KB')
      // console.log(editorAppMetaData.appManifest)

      // console.log('filtered', new Blob([JSON.stringify(filteredManifest, null, 2)]).size / 1024, 'KB')
      // console.log(filteredManifest)

      api.interactionEnded('get-app-manifest')
      return filteredManifest
    } catch (err) {
      handleError(err, {
        extra: { message: 'Failed to load filtered manifest' },
      })

      return editorAppMetaData.appManifest
    }
  } catch (err) {
    handleError(err, {
      extra: { message: 'Failed to load manifest' },
    })
  }
}
