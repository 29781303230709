import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
  BASE_DESIGN_GROUPS,
} from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { CUSTOM_FIELD } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { ComponentLayout } from '@wix/platform-editor-sdk'

export const makeGeneralCheckboxOption = () => ({
  type: 'CheckboxInput',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  label: '',
  checked: true,
})

export class GeneralCheckbox extends IGeneralField {
  protected get icon() {
    return iconNames.multiSelectCheckbox
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_CHECKBOX
  }

  public get collectionFieldType() {
    return FieldCollectionType.TAGS
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.CHECKBOX_GROUP
  }

  public get role() {
    return FIELDS.ROLE_FIELD_CHECKBOX_GROUP
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return true
  }

  public get fieldWithOptions() {
    return true
  }

  public get alwaysShowLabel() {
    return true
  }

  protected get skin() {
    return 'skins.input.CheckboxGroupDefaultSkin'
  }

  protected get layout() {
    return {
      width: 240,
      height: 127,
    }
  }

  protected get layoutResponsive() {
    const componentLayout: ComponentLayout = {
      type: 'ComponentLayout',
      height: {
        type: 'auto',
      },
      breakpoint: undefined,
    }
    return {
      componentLayouts: [componentLayout],
    }
  }

  protected get props() {
    return {
      type: 'CheckboxGroupProperties',
      alignment: 'left',
      layout: 'vertical',
      buttonsMargin: 17,
      spacing: 16,
      buttonSize: 18,
      labelMargin: 24,
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.selection,
          subCategory: CATEGORIES.basic,
        },
      },
    }
  }

  public get base() {
    return GeneralCheckbox.prototype
  }

  public get manifest() {
    return fieldManifest({
      displayName: this.translate('fields.labelName.multiCheckbox'),
      duplicatable: true,
      keepMainAction2: true,
    })
  }

  protected get data(): any {
    return {
      type: 'CheckboxGroup',
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
      label: this.translate(`preset.${this.fieldType}Label`),
      options: [
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
          label: this.translate(`preset.${this.fieldType}FirstOptionsLabel`),
        },
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}SecondOptionsValue`),
          label: this.translate(`preset.${this.fieldType}SecondOptionsLabel`),
        },
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}ThirdOptionsValue`),
          label: this.translate(`preset.${this.fieldType}ThirdOptionsLabel`),
          checked: false,
        },
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}ForthOptionsValue`),
          label: this.translate(`preset.${this.fieldType}ForthOptionsLabel`),
          checked: false,
        },
      ],
    }
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd'],
        [BASE_DESIGN_GROUPS.INPUT_CORNERS]: ['rd'],
        [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['bgc', 'bgch', 'bgcd'],
        [BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]: ['fnt'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txt2', 'txt'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fnt2'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['bgh'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['brdh'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bgf'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brdf'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bge'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brde'],
        [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
        [BASE_DESIGN_GROUPS.INPUT_SHADOW]: ['shd'],
      },
    }
  }

  public get layoutComponentProps() {
    return ['alignment', 'labelMargin', 'buttonsMargin', 'spacing', 'buttonSize', 'layout']
  }

  protected get styleProperties() {
    return {
      properties: {
        'alpha-bg': '1',
        'alpha-bg2h': '0',
        'alpha-bg3': '0.55',
        'alpha-bg3e': '0',
        'alpha-bg3h': '1',
        'alpha-bgd': '0',
        'alpha-bgf': '1',
        'alpha-bgh': '1',
        'alpha-border_color_focus': '1',
        bg: 'rgba(255,255,255,1)',
        bg2: 'color_18',
        bg2d: '#919191',
        bg2h: 'color_18',
        bg3: 'color_15',
        bg3e: '#6EB7FD',
        bg3f: '#00A6FF',
        bg3h: 'color_18',
        bgd: '#FFFFFF',
        bge: '#f60419',
        bgf: 'color_18',
        bgh: 'color_11',
        bgc: 'color_18',
        bgch: 'color_18',
        bgcd: 'color_18',
        border_color_focus: 'color_18',
        'boxShadowToggleOn-shd': 'false',
        brd: '#888888',
        brw: '1',
        brwd: '0',
        brwe: '1',
        brwf: '1',
        brwh: '1',
        fnt: 'font_8',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        txt: 'color_15',
        txt2: 'color_18',
        fnt2: 'font_8',
        rd: '2px',
      },
    }
  }
}
