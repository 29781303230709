import _ from 'lodash'
import { COMPLEX_ADDRESS_ROLES } from '../../../constants/roles'
import { getFieldByRole } from '../fields/utils'

export const getStreetField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.STREET)
}
export const getStreet2Field = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.STREET2)
}
export const getCityField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.CITY)
}
export const getCountryField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.COUNTRY)
}
export const getStateField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.STATE)
}
export const getZipCodeField = (subFields: FormField[]): FormField => {
  return getFieldByRole(subFields, COMPLEX_ADDRESS_ROLES.ZIP_CODE)
}
export const getCountryOptions = (subFields: FormField[]): FieldOption[] => {
  return _.get(getCountryField(subFields), 'options') || []
}
