import _ from 'lodash'
import { getGroupType } from '../manifests/global-design-manifest'
import { calcSecondMostCommonValueInArray, calcCommonValueInArray } from '../utils'
import { BASE_DESIGN_GROUPS } from '@wix/forms-common'
import { fieldsStore } from '../preset/fields/fields-store'
import { FontOption } from '@wix/platform-editor-sdk'

export const PARAM_TYPE_DEFAULT_VALUE = {
  ALPHA: 1,
  SHADOW: 'false',
  BG_COLOR_ALPHA: '#ffffff',
  BORDER_COLOR_ALPHA: '#e3e3e3',
  BORDER_SIZES: '1px',
  BORDER_RADIUS: 0,
  TEXT_COLOR: '#000000',
  FONT: 'font_8',
  BOX_SHADOW: '0 0 0 rgba(0, 0, 0, 0)',
}

const PARAMS_ALPHA = {
  'bg-calendar': 1,
}

const PARAMS_DEFAULT_VALUES = {
  'bg-day-selected': 'color_12',
  'txt-day': '#ffffff',
  'txt-header': '#ffffff',
}

export type designMapping = { [key in BASE_DESIGN_GROUPS]?: string[] }
export type commonStyles = {
  [key in BASE_DESIGN_GROUPS]?: { value: string | number; alpha?: number; isToggleOn?: string }
}

const getGroupValuesFromStyle = (
  group: BASE_DESIGN_GROUPS,
  style: any,
  designMapping: designMapping,
  groupType: string,
  prefix?: string,
) => {
  const mappedStyleParams = designMapping[group]
  return !mappedStyleParams
    ? [PARAM_TYPE_DEFAULT_VALUE[groupType]]
    : mappedStyleParams.map(
        (p) => _.get(style, `${prefix}${p}`) || PARAM_TYPE_DEFAULT_VALUE[groupType],
      )
}

const getGroupValuesFromStyles = (
  group: BASE_DESIGN_GROUPS,
  fields: { style: any; designMapping: designMapping }[],
  groupType: string,
  prefix: string = '',
) => {
  return _.flatMap(fields, ({ style, designMapping }) =>
    getGroupValuesFromStyle(group, style, designMapping, groupType, prefix),
  )
}

const handleEqualBackgroundAndText = (
  commonStyles: commonStyles,
  backgroundGroup: BASE_DESIGN_GROUPS,
  fields,
) => {
  const backgroundColor = commonStyles[backgroundGroup].value
  if (
    commonStyles[backgroundGroup].alpha === 1 &&
    (backgroundColor === commonStyles[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR].value ||
      backgroundColor === commonStyles[BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR].value)
  ) {
    const groupValues = getGroupValuesFromStyles(backgroundGroup, fields, 'BG_COLOR_ALPHA')
    const groupValue = calcSecondMostCommonValueInArray(groupValues)
    commonStyles[backgroundGroup] = {
      ...commonStyles[backgroundGroup],
      value: groupValue,
    }
  }
  return commonStyles
}

const handleEqualBackgroundsAndText = (commonStyles: commonStyles, fields) => {
  ;[
    BASE_DESIGN_GROUPS.INPUT_BACKGROUND,
    BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR,
    BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS,
    BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS,
    BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER,
  ].forEach((group) => {
    commonStyles = handleEqualBackgroundAndText(commonStyles, group, fields)
  })
  return commonStyles
}

export const getGroupValue = (
  group: BASE_DESIGN_GROUPS,
  fields: { style: any; designMapping: designMapping }[],
) => {
  const groupType = getGroupType(group)
  const groupValues = getGroupValuesFromStyles(group, fields, groupType)
  const groupValue = calcCommonValueInArray(groupValues)

  if (_.includes(groupType, 'ALPHA')) {
    const alphaValues = getGroupValuesFromStyles(group, fields, 'ALPHA', 'alpha-')
    const alphaValue = calcCommonValueInArray(alphaValues)
    return { value: groupValue, alpha: alphaValue }
  }

  if (_.includes(groupType, 'SHADOW')) {
    const boxShadowToggleOnValues = getGroupValuesFromStyles(
      group,
      fields,
      'SHADOW',
      'boxShadowToggleOn-',
    )
    const boxShadowToggleOnValue = calcCommonValueInArray(boxShadowToggleOnValues)
    return { value: groupValue, isToggleOn: boxShadowToggleOnValue }
  }

  return { value: groupValue }
}

export const calcCommonStyleGlobalDesign = (
  fields: { style: any; designMapping: designMapping }[],
): commonStyles => {
  const commonStyles = _.reduce(
    BASE_DESIGN_GROUPS,
    (acc, group) => {
      acc[group] = getGroupValue(group, fields)
      return acc
    },
    {},
  )
  return handleEqualBackgroundsAndText(commonStyles, fields)
}

const dateHasBadContrast = (param, commonStyles: commonStyles) =>
  (param === 'bg-day-selected' &&
    commonStyles[BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR].value ===
      commonStyles[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR].value) ||
  ((param === 'txt-day' || param === 'txt-header') &&
    commonStyles[BASE_DESIGN_GROUPS.INPUT_BACKGROUND].value ===
      commonStyles[BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR].value)

export const getFieldStyle = (commonStyles: commonStyles, fieldType: FieldPreset) => {
  const componentType = fieldsStore.get(fieldType).properties.componentType
  const designMapping = _.get(fieldsStore.get(fieldType).designMapping, componentType)
  if (!designMapping) return {}

  return _.reduce(
    designMapping,
    (acc, styleParams, group: BASE_DESIGN_GROUPS) => {
      styleParams.forEach((param) => {
        const groupValue = commonStyles[group]
        if (!groupValue) return
        if (_.includes(getGroupType(group), 'ALPHA')) {
          acc[`alpha-${param}`] = PARAMS_ALPHA[param] || groupValue.alpha
        }
        if (_.includes(getGroupType(group), 'SHADOW')) {
          acc[`boxShadowToggleOn-${param}`] = groupValue.isToggleOn
        }
        acc[param] = dateHasBadContrast(param, commonStyles)
          ? PARAMS_DEFAULT_VALUES[param]
          : groupValue.value
      })
      return acc
    },
    {},
  )
}

export const getFontFamily = (fontStr: string, fontOptions: FontOption[]): string => {
  const fontFamily = fontStr.split(' ')[4]
  const cleanFontName = fontFamily.replace(/\+/g, ' ').toLowerCase()
  const fonts = _.flatMap(fontOptions.map((option) => option.fonts))
  const fontCss = _.get(
    _.find<any>(fonts, { fontFamily: cleanFontName }),
    'cssFontFamily',
    cleanFontName,
  )
  return fontCss.replace(/"/g, `'`)
}

const parseFontStr = (fontStr: string, fontOptions: FontOption[]) => {
  const split = fontStr.split(' ')
  const sizeSplit = split[3] ? split[3].split('/') : []
  return {
    style: split[0],
    variant: split[1],
    weight: split[2],
    size: sizeSplit[0],
    lineHeight: sizeSplit[1],
    family: getFontFamily(fontStr, fontOptions),
    color: split[5],
    bold: split[2] === 'bold' || parseInt(split[2], 10) >= 700,
    italic: split[0] === 'italic',
  }
}

export const htmlTextFromStyle = (
  text: string,
  {
    color,
    font,
    textAlignment,
    dir,
    fontOptions,
    colorAsTheme,
    fontAsTheme,
  }: {
    color: string
    font: string
    fontOptions: FontOption[]
    textAlignment?: string
    dir?: string
    colorAsTheme?: boolean
    fontAsTheme?: boolean
  },
) => {
  if (_.startsWith(font, 'font_') || fontAsTheme) {
    return fntP({
      textAlignment,
      dir,
      fontClass: font,
      content: themedFntSpan({ color, colorAsTheme, text }),
    })
  } else {
    const parsedFont = parseFontStr(font, fontOptions)
    const content = customFntSpan({ ...parsedFont, color, colorAsTheme, text })

    return fntP({
      lineHeight: parsedFont.lineHeight,
      textAlignment,
      dir,
      content,
    })
  }
}

export const fntP = ({ fontClass = null, lineHeight = null, textAlignment, dir = null, content }) => {
  const dirStyle = dir ? `dir:${dir};` : ''
  const classF = fontClass ? `class="${fontClass}" ` : ''
  const textAlignmentStyle = textAlignment ? `text-align:${textAlignment};` : ''
  const lineHeightStyle = lineHeight ? `line-height:${lineHeight};` : ''
  return `<p ${classF}style="${lineHeightStyle}${textAlignmentStyle}${dirStyle}">${content}</p>`
}

export const themedFntSpan = ({ colorAsTheme = false, color, text }) => {
  const colorSpan =
    _.startsWith(color, 'color_') || colorAsTheme
      ? `<span class="${color}">`
      : `<span style="color:${color};">`
  return `${colorSpan}${text}</span>`
}

export const customFntSpan = ({
  text,
  colorAsTheme = false,
  fontTheme = null,
  color,
  variant,
  style,
  weight,
  size,
  decorate = null,
  family = null,
  lineHeight = null,
  align = null,
}) => {
  const lintHeightStyle = lineHeight ? `line-height: ${lineHeight};` : ''
  const alignStyle = align ? `text-align: ${align};` : ''
  const colorSpan =
    _.startsWith(color, 'color_') || colorAsTheme
      ? `<span class="${color}">`
      : `<span style="color:${color};">`
  const decorateSpan = decorate
    ? `<span style="text-decoration:${decorate};">`
    : `<span style="text-decoration: none;">`
  const themeFntSpan = fontTheme
    ? `<span class="${fontTheme}" style="${alignStyle}">`
    : `<span style="font-family:${family};${alignStyle}">`
  return `${themeFntSpan}${colorSpan}${decorateSpan}<span style="font-variant:${variant}"><span style="font-style:${style}"><span style="font-weight:${weight}"><span style="font-size:${size};${lintHeightStyle}">${text}</span></span></span></span></span></span></span>`
}

const changeFontValueAt = (fullFont: string, changedValue: string, idx: number) =>
  fullFont
    .split(' ')
    .map((f, _idx) => (_idx === idx ? changedValue : f))
    .join(' ')

export const changeFontFamily = (fullFont: string, newFontFamily: string) =>
  changeFontValueAt(fullFont, newFontFamily, 4)
export const removeFontColor = (fullFont: string) => changeFontValueAt(fullFont, '', 5)
