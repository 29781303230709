import _ from 'lodash'

const COUNTRY_TRANSLATION_PREFIX = 'locale-dataset.countries.'
const locale = 'en'
const dataset = require(`./resources/resources_${locale}.json`)

export interface Country {
  key: string
}

class LocaleData {
  getAllCountries() {
    return _.chain(dataset)
      .keys()
      .map((resourceKey) => ({
        key: resourceKey.split(COUNTRY_TRANSLATION_PREFIX)[1],
      }))
      .orderBy((val) => this.getCountryByKey(val.key))
      .value()
  }

  getCountryByKey(key: string) {
    return dataset[`${COUNTRY_TRANSLATION_PREFIX}${key}`]
  }
}

export default new LocaleData()
