import _ from 'lodash'
import translations from '../../../utils/translations'
import { AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '@wix/forms-common'
import { GET_SUBSCRIBERS_HELP_ID, GFPP } from './manifest-commons'
import { getAppVersion, isProduction } from '../../../utils/utils'

const getAppVersionForAssets = () => {
  return !isProduction() || getAppVersion() === '0.0.0' ? '1.2550.0' : getAppVersion()
}

export const createFirstTimeExperienceManifest = (
  { plugins }: AppStateObject = {},
  controllerType: ControllerType | 'singlePostController',
) => {
  const { t } = translations
  const isMultiStepForm =
    _.includes(plugins, FormPlugin.MULTI_STEP_FORM) || controllerType === 'multiStepForm'

  const isGetSubscribersForm =
    _.includes(plugins, FormPlugin.GET_SUBSCRIBERS) || controllerType === 'getSubscribers'

  const isRegistrationForm =
    _.includes(plugins, FormPlugin.REGISTRATION_FORM) || controllerType === 'registrationForm'

  if (isGetSubscribersForm)
    return {
      firstTimeExperience: {
        contentTitle: t('firstTimePanel.getSubscribers.title'),
        content: t('firstTimePanel.getSubscribers.subtitle'),
        linkText: t('firstTimePanel.getSubscribers.learnMore'),
        helpId: GET_SUBSCRIBERS_HELP_ID,
        headerBg: `http://static.parastorage.com/services/wix-form-builder/${getAppVersionForAssets()}/assets/gifs/first-time-experience/get-subscribers-first-time.gif`,
        headerBgRetina: `http://static.parastorage.com/services/wix-form-builder/${getAppVersionForAssets()}/assets/gifs/first-time-experience/get-subscribers-first-time-retina.gif`,
        bgColor: '#542790',
      },
    }

  if (isRegistrationForm)
    return {
      firstTimeExperience: {
        contentTitle: t('firstTimePanel.registrationForm.title'),
        content: t('firstTimePanel.registrationForm.subtitle'),
        linkText: t('firstTimePanel.registrationForm.learnMore'),
        helpId: GFPP.HELP_ID.REGISTRATION_FORM_CONTAINER,
        headerBg: `http://static.parastorage.com/services/wix-form-builder/${getAppVersionForAssets()}/assets/gifs/first-time-experience/registration-form.gif`,
        headerBgRetina: `http://static.parastorage.com/services/wix-form-builder/${getAppVersionForAssets()}/assets/gifs/first-time-experience/registration-form-retina.gif`,
        bgColor: '#5fbc57',
      },
    }

    if (isMultiStepForm)
    return {
      firstTimeExperience: {
        contentTitle: t('firstTimePanel.multiStep.title'),
        content: t('firstTimePanel.multiStep.subtitle'),
        headerBg: `http://static.parastorage.com/services/wix-form-builder/${getAppVersionForAssets()}/assets/gifs/first-time-experience/multistep-first-time.gif`,
        headerBgRetina: `http://static.parastorage.com/services/wix-form-builder/${getAppVersionForAssets()}/assets/gifs/first-time-experience/multistep-first-time-retine.gif`,
        bgColor: '#25C0C7',
      },
    }
  return {}
}
