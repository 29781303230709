import _ from 'lodash'

export const ErrorName = {
  FailedToSaveSite: 'FailedToSaveSite',
  FailedToCallApi: 'FailedToCallApi',
  Permissions: 'NoPermissions',
}

export type FormError = FailedToSaveSiteError | FailedToCallApiError

export class FailedToSaveSiteError extends Error {
  public readonly data

  constructor(error) {
    const description = _.get(error, 'documentServices.errorDescription')
    const errorMessage = _.get(error, 'error')
    const errorType = _.get(error, 'document.errorType')
    super(`Failed to save site: ${description || errorMessage || errorType}`)
    this.name = ErrorName.FailedToSaveSite
    this.data = error
  }
}

export class FailedToCallApiError extends Error {
  public readonly data

  constructor(funcName, error) {
    const httpStatus = _.get(error, 'httpStatus')
    const message = httpStatus
      ? `Failed to call api: ${funcName} with status code ${httpStatus}`
      : `Failed to call api: ${funcName}`
    super(message)
    this.name = ErrorName.FailedToCallApi
    this.data = error
  }
}
