import { IGeneralField } from '../general-field'
import { FirstName } from './definitions/first-name'
import { LastName } from './definitions/last-name'
import { Company } from './definitions/company'
import { Position } from './definitions/position'
import { Email } from './definitions/email'
import { Address } from './definitions/address'
import { Phone } from './definitions/phone'
import { Birthday } from './definitions/birthady'
import { Anniversary } from './definitions/anniversary'
import { Website } from './definitions/webstie'
import { AgreeTerms } from './definitions/agree-terms'
import { GeneralRecaptcha } from '../general-fields/definitions/general-recaptcha'
import { GeneralAutoCompleteAddress } from '../general-fields/definitions/general-autocomplete-address'
import { ComplexPhoneWidget } from '../complex-fields/complex-phone/definitions/complex-phone-widget'
import { ComplexAddressWidget } from '../complex-fields/complex-address/definitions/complex-address-widget'

export const createRecommendedFields = (): IGeneralField[] => [
  new FirstName(),
  new LastName(),
  new GeneralRecaptcha(),
  new Email(),
  new Phone(),
  new ComplexPhoneWidget(),
  new GeneralAutoCompleteAddress(),
  new ComplexAddressWidget(),
  new Address(),
  new Birthday(),
  new Company(),
  new Position(),
  new AgreeTerms(),
  new Anniversary(),
  new Website(),
]
