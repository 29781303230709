import { ConvertEmailRequest, WixFormBuilderWeb } from '@wix/ambassador-wix-form-builder-web/http'
import {
  AddFormIdMappingRequest,
  FormSummary,
  PublishSiteRequest,
} from '@wix/ambassador-wix-form-builder-web/types'
import _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../../constants'
import { withFedops } from '../../editor-app/core/decorators'
import { FORMS_SERVER } from './constants/external-endpoints'

export class FormsServiceAmbassadorClient {
  private callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' }
  constructor(private boundEditorSDK: BoundEditorSDK, private fedopsLogger) {}

  private async _getAppInstance() {
    return this.boundEditorSDK.info.getAppInstance()
  }

  private _getBaseServicesClient(baseUrl: string = FORMS_SERVER.BASE_URL) {
    return WixFormBuilderWeb(baseUrl, { ignoredProtoHttpUrlPart: '/api' })
  }

  private async _getRequestHeader() {
    return { Authorization: await this._getAppInstance(), ...this.callerId }
  }

  public async getEmailsByEmailIds(emailIds: string[]) {
    return this._getBaseServicesClient()
      .EmailService()(await this._getRequestHeader())
      .getEmails({
        emailIds: _.compact(emailIds),
      })
  }

  public async getSiteUsers() {
    return this._getBaseServicesClient()
      .EmailService()(await this._getRequestHeader())
      .listSiteUsersEmail({})
  }

  public async addEmail(email: string) {
    return this._getBaseServicesClient()
      .EmailService()(await this._getRequestHeader())
      .addEmail({ email })
  }

  public publishSite = async (data: PublishSiteRequest) => {
    return this._getBaseServicesClient()
      .FormBuilderService()(await this._getRequestHeader())
      .publishSite(data)
  }

  public addContactFormMapping = async (data: AddFormIdMappingRequest) => {
    return this._getBaseServicesClient()
      .ContactFormService()(await this._getRequestHeader())
      .addFormIdMapping(data)
  }

  public async editDraft(form: FormSummary) {
    return this._getBaseServicesClient()
      .FormBuilderService()(await this._getRequestHeader())
      .editDraft({ form })
  }

  public async getRestrictions() {
    return this._getBaseServicesClient()
      .PremiumService()(await this._getRequestHeader())
      .restrictions({})
  }

  public async convertContactFormEmails(convertContactFormEmailsRequest: ConvertEmailRequest) {
    return this._getBaseServicesClient()
      .ContactFormService()(await this._getRequestHeader())
      .convertEmail(convertContactFormEmailsRequest)
  }

  public async getForm(formEditorId: string) {
    return this._getBaseServicesClient(FORMS_SERVER.BASE_URL_V2)
      .FormsService()(await this._getRequestHeader())
      .getFormByExternalId({ externalId: formEditorId })
      .then((res) => res.form)
  }

  public async getFormByGuid(formId: string) {
    return this._getBaseServicesClient(FORMS_SERVER.BASE_URL_V2)
      .FormsService()(await this._getRequestHeader())
      .getForm({ formId })
      .then((res) => res.form)
  }

  private async _updateFormName({
    publicId,
    name,
    revision,
  }: {
    publicId: string
    name: string
    revision: number
  }) {
    await this._getBaseServicesClient(FORMS_SERVER.BASE_URL_V2)
      .FormsService()(await this._getRequestHeader())
      .updateForm({
        formId: publicId,
        revision,
        info: { name },
        fieldMask: { paths: ['name'] },
      })
  }

  public async updateFormName({
    publicId,
    name,
    revision,
  }: {
    publicId: string
    name: string
    revision: number
  }) {
    await this._updateFormName({ publicId, name, revision }).catch(async (e) => {
      if (e.httpStatus === 428) {
        const currentForm = await this.getFormByGuid(publicId)
        return this._updateFormName({ publicId, name, revision: currentForm.revision })
      }
    })
  }

  @withFedops('get-form-names')
  public async getSimilarFormNames({ name }: { name: string }): Promise<string[]> {
    return this._getBaseServicesClient(FORMS_SERVER.BASE_URL_V2)
      .FormsService()(await this._getRequestHeader())
      .queryForms({
        query: {
          filter: {
            $and: [
              {
                name: { $contains: name },
              },
              {
                app_id: FORMS_APP_DEF_ID,
              },
              {
                status: {
                  $in: ['DRAFT', 'PUBLISHED'],
                },
              },
            ],
          },
          fields: [],
          sort: [],
          fieldsets: [],
          paging: { offset: 0, limit: 100 },
        },
        fieldMask: { paths: ['name'] },
      })
      .then((res) => (res.forms ? res.forms.map((form) => form.name) : []))
  }

  @withFedops('get-other-form-names')
  public async getOtherFormNames({ publicId }: { publicId: string }): Promise<string[]> {
    return this._getBaseServicesClient(FORMS_SERVER.BASE_URL_V2)
      .FormsService()(await this._getRequestHeader())
      .queryForms({
        query: {
          filter: {
            $and: [
              {
                app_id: FORMS_APP_DEF_ID,
              },
              {
                status: {
                  $in: ['DRAFT', 'PUBLISHED'],
                },
              },
              {
                id: {
                  $ne: publicId,
                },
              },
            ],
          },
          fields: [],
          sort: [],
          fieldsets: [],
          paging: { offset: 0, limit: 100 },
        },
        fieldMask: { paths: ['name'] },
      })
      .then((res) => (res.forms ? res.forms.map((form) => form.name) : []))
  }
}
