import { editorReady as editorReadyImpl } from './editor-ready'
import { getAppManifest as getAppManifestImpl } from './get-app-manifest'
import { onEvent as onEventImpl } from './on-event'
import { generateExportedApi } from '../core/api-generator'
import { editorAppMetaData } from './editor-app'
import { handleAction as handleActionImpl } from './handle-action'

export const editorReady = (editorSDK, appDefinitionId, payload) => {
  return editorReadyImpl(editorSDK, appDefinitionId, payload)
}

export const getAppManifest = (options, editorSDK) => {
  return getAppManifestImpl(options, editorSDK)
}

export const onEvent = (eventPayload, eventType) => {
  return onEventImpl(eventPayload, eventType)
}

export const handleAction = (payload, editorSDK) => {
  return handleActionImpl(payload, editorSDK)
}

export const exports = generateExportedApi(editorAppMetaData)
