import {
  ElementsLayout,
  GetSubscribersEnhancer,
  GSExtraData,
  GSLayout,
} from './get-subscribers-types'
import { isSideLabelSkinGS, isSkinWithFieldTitlesGS } from './get-subscribers-utils'
import { FIELDS, FIELDS_ROLES } from '../../../../constants/roles'
import _ from 'lodash'
import greyBox from '../../../../assets/presets/get-subscribe-form-grey-box.json'
import triangle from '../../../../assets/presets/get-subscribe-form-grey-triangle.json'
import postStamp from '../../../../assets/presets/get-subscribe-form-post-stamp.json'
import stamp from '../../../../assets/presets/get-subscribe-form-stamp.json'
import { convertStampStyle, designBoxStyle } from './get-subscribers-style'
import {
  layoutStamp,
  layoutPostStamp,
  getElementLayout,
  getHeightAndWidthFromRect,
} from './get-subscribers-layout'
const fixSideLabelSkin: GetSubscribersEnhancer = (settings, convertedForm) => {
  if (!isSideLabelSkinGS(settings)) {
    return convertedForm
  }

  convertedForm.components = _.map(convertedForm.components, (comp) => ({
    ...comp,
    components: comp.components.reduce((currComponents, component) => {
      if (_.includes(FIELDS_ROLES, component.role)) {
        const isComplexPhone = component.role === FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET

        const updateObject = isComplexPhone
          ? {
              components: [
                {
                  components: [
                    {},
                    {
                      data: { placeholder: '' },
                      props: { placeholder: '' },
                    },
                  ],
                },
              ],
            }
          : {
              data: { placeholder: '' },
              props: { placeholder: '' },
            }
        const inputComponent = _.merge({}, component, updateObject)

        return [...currComponents, inputComponent]
      } else {
        return [...currComponents, component]
      }
    }, []),
  }))
  return convertedForm
}

const emailEnhancer: GetSubscribersEnhancer = (settings, structure) => {
  const supportNotifications = settings.generalSettings.subscriptionNotifications
  const emails = supportNotifications ? [settings.email].filter((email) => email) : []

  return {
    ...structure,
    config: {
      ...structure.config,
      emailIds: emails,
    },
  }
}

const fixLayoutHeight = (layout, GsLayouts: ElementsLayout, limitHeightProp) => {
  const heightLimit = getHeightAndWidthFromRect(_.get(GsLayouts, limitHeightProp)).height
  console.log('heightLimit', heightLimit)
  return {
    ...layout,
    height: _.min([layout.height, heightLimit - layout.y]),
  }
}
const designElementWithLayout = (
  element,
  {
    extraData,
    containerProp,
    limitHeightProp,
  }: {
    extraData: GSExtraData
    containerProp: string
    limitHeightProp: string
  },
  style = {},
  getLayout: Function = getElementLayout,
) => {
  const desktopLayout = _.get(extraData.desktopLayouts, containerProp)
  const mobileLayouts = _.get(extraData.mobileLayouts, containerProp)

  const layout = getLayout(desktopLayout, element.layout)
  const mobileLayout = getLayout(mobileLayouts, element.layout)

  const fixedLayout = fixLayoutHeight(layout, extraData.desktopLayouts, limitHeightProp)
  const fixedMobileLayout = fixLayoutHeight(mobileLayout, extraData.mobileLayouts, limitHeightProp)

  return _.merge({}, element, {
    layout: fixedLayout,
    style,
    ...(mobileLayouts
      ? {
          mobileStructure: {
            layout: {
              ...element.layout,
              ...fixedMobileLayout,
            },
          },
        }
      : {}),
  })
}

const addDesignComponents: GetSubscribersEnhancer = (settings, convertedForm, extraData) => {
  const { appStyle } = settings.style

  const getDesignComponents = (stepIndex) => {
    const containerProp =
      appStyle === 0 ? 'headerContainer' : stepIndex === 0 ? 'appContainer' : 'successAppContainer'
    const limitHeightProp = stepIndex === 0 ? 'iframeRect' : 'iframeSuccessRect'

    const designData = {
      extraData,
      containerProp,
      limitHeightProp,
    }

    let designComponents = []
    const boxStyle = designBoxStyle(settings, extraData)
    let box = designElementWithLayout(greyBox, designData, { style: boxStyle })
    switch (appStyle) {
      case 0:
        if (stepIndex === 0) {
          designComponents.push(box)
        }
        break
      case 2:
        const postStampComp = designElementWithLayout(postStamp, designData, {}, layoutPostStamp)
        designComponents.push(box, postStampComp)
        break
      case 3:
        const stampComp = designElementWithLayout(stamp, designData, {}, layoutStamp)
        const styledStamp = convertStampStyle(stampComp, extraData)
        designComponents.push(box, styledStamp)
        break
      case 4:
        const triangleComp = _.cloneDeep(triangle)
        designComponents.push(box, triangleComp)
        break
      default: {
      }
    }
    return designComponents
  }

  convertedForm.components = _.map(convertedForm.components, (comp, ind) => ({
    ...comp,
    components: [...getDesignComponents(ind), ...comp.components],
  }))

  return {
    ...convertedForm,
  }
}

export const hideFormInMobile: GetSubscribersEnhancer = (
  _setting,
  structure,
  extraData,
): RawComponentStructure => {
  if (!extraData.hiddenInMobile) {
    return structure
  }
  const hideFormInMobileAction: (structure: RawComponentStructure) => RawComponentStructure = (
    structure,
  ) => {
    const structureWithMobileHidden: RawComponentStructure = {
      ...structure,
      mobileHints: { hidden: true, type: 'MobileHints' },
    }
    if (structure.components) {
      return {
        ...structureWithMobileHidden,
        components: structureWithMobileHidden.components.map((component) =>
          hideFormInMobileAction(component as RawComponentStructure),
        ),
      }
    } else {
      return structureWithMobileHidden
    }
  }
  return hideFormInMobileAction(structure)
}

export const mobileStructureStatesContainer: GetSubscribersEnhancer = (
  setting,
  structure,
  extraData,
  getSubscribersForm,
): RawComponentStructure => {
  if (extraData.hiddenInMobile) {
    return structure
  }

  const measures = getHeightAndWidthFromRect(extraData.mobileLayouts.iframeRect)
  const measuresSuccess = getHeightAndWidthFromRect(extraData.mobileLayouts.iframeSuccessRect)
  let stepsLayouts = [measures, measuresSuccess].map((l) => ({ ...l, x: 0, y: 0 }))

  const containerMobileStructure = {
    layout: {
      x: getSubscribersForm.mobileStructure.layout.x,
      y: getSubscribersForm.mobileStructure.layout.y,
      ...measures,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  }

  const mobileStructures = stepsLayouts.map((l) => ({
    layout: {
      ...l,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  }))

  return {
    ...structure,
    mobileStructure: containerMobileStructure,
    components: structure.components.map((comp, i) => ({
      ...comp,
      mobileStructure: mobileStructures[i],
    })),
  }
}

export const layoutStatesContainer: GetSubscribersEnhancer = (
  _setting,
  structure,
  extraData,
): RawComponentStructure => {
  const measures = getHeightAndWidthFromRect(extraData.desktopLayouts.iframeRect)
  const measuresSuccess = getHeightAndWidthFromRect(extraData.desktopLayouts.iframeSuccessRect)
  let layouts = [measures, measuresSuccess].map((l) => ({ ...l, x: 0, y: 0 }))

  return {
    ...structure,
    components: structure.components.map((comp, i) => ({
      ...comp,
      layout: layouts[i],
    })),
  }
}

export const updateConfigLayoutProps: GetSubscribersEnhancer = (
  settings,
  structure,
): RawComponentStructure => {
  const email = structure.components[0].components.find((s) => s.subRole === 'subRole_email')

  return _.merge(structure, {
    config: {
      labelPadding: 0,
      labelMargin: email.props.labelMargin,
      textPadding: email.props.textPadding,
      showFieldsTitle: isSkinWithFieldTitlesGS(settings),
      textAlignment: settings.style.bodyAlignment === 1 ? 'right' : 'left',
    },
  })
}

export const formEnhancers: GetSubscribersEnhancer[] = [
  addDesignComponents,
  fixSideLabelSkin,
  emailEnhancer,
  hideFormInMobile,
  layoutStatesContainer,
  mobileStructureStatesContainer,
  updateConfigLayoutProps,
]
