import _ from 'lodash'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { isComplexAddressInnerField, isInputField } from '../utils'
import { FieldDataForCollectionActions } from './api'

export const shouldAddFieldToCollection = ({ role, fieldType }: FieldDataForCollectionActions) =>
  (isInputField(role) || isComplexAddressInnerField(role)) && allowCollectionSync(fieldType)

export const filterFieldsToCollection = <T extends FieldDataForCollectionActions>(
  fields: T[],
): T[] => _.filter(fields, (field) => shouldAddFieldToCollection(field))
