// TODO: find a better place for this file
export const CATEGORIES = {
  none: 'none',
  recommended: 'recommended', //deprecated
  text: 'text',
  number: 'number',
  selection: 'selection',
  date: 'dateTime',
  upload: 'upload',
  subscription: 'subscription',
  registration: 'registration',
  contact: 'contact',
  basic: 'basic',
  advanced: 'advanced',
  url: 'url',
  recaptcha: 'recaptcha',
  signature: 'signature',
}

export const REGISTRATION_FORM_CATEGORY = 'registration'

export const MENU_ACTIONS_OVERRIDE = {
  toggleDefault: {
    enable: false,
  },
  duplicate: {
    enable: false,
  },
  toggleEditLabel: {
    enable: false,
  },
  toggleEditValue: {
    enable: false,
  },
  delete: {
    enable: false,
  },
}
