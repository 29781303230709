enum ExtendApiNames {
  settingsGetCrucialElements = 'settings.getCrucialElements',
  fieldsGetNewFields = 'fields.getNewFields',
  appConfig = 'appConfig',
  onEvent = 'onEvent',
}

type ReturnValue = any

export interface FormsExtendApi {
  [ExtendApiNames.settingsGetCrucialElements]: ReturnValue
  [ExtendApiNames.fieldsGetNewFields]: ReturnValue
  [ExtendApiNames.appConfig]: ReturnValue
  [ExtendApiNames.onEvent]: ReturnValue
}

export interface ExtendApi extends Partial<FormsExtendApi> {}

export abstract class ExtendApiCreator {
  get events(): { [x: string]: Function } {
    return {}
  }

  createOnEvent() {
    return ({ eventType, eventPayload }) => {
      if (this.events[eventType]) {
        this.events[eventType](eventPayload)
      }
    }
  }

  abstract getExtendApi(): ExtendApi
}
