import _ from 'lodash'
export const GSTextInputFields = ['name', 'email', 'phoneCode', 'phoneNumber', 'phone']

export const isWithBottomRdOnly = (settings: GetSubscribersSettings) =>
  settings.style.appStyle === 3

export const isSkinWithStepBackground = (settings: GetSubscribersSettings) =>
  _.includes([0, 1], settings.style.appStyle)

export const isSkinWithGradientBackground = (settings: GetSubscribersSettings) =>
  _.includes([3, 4], settings.style.appStyle)

export const isSideLabelSkinGS = (settings: GetSubscribersSettings) =>
  settings.style.appStyle === 2 && settings.signupForm.layout === 1

export const isSkinWithFieldTitlesGS = (settings: GetSubscribersSettings) =>
  settings.style.appStyle === 4 && settings.signupForm.layout === 1

export const isSkinWithMiddleInputLayoutGS = (settings: GetSubscribersSettings) =>
  settings.style.appStyle === 4 && settings.signupForm.layout === 0

export const isSkinFieldHeightFromContainerGS = (settings: GetSubscribersSettings) =>
  _.includes([0, 3], settings.style.appStyle)

export const isInputBorderBottomGS = (appStyle: number) => appStyle === 0 || appStyle === 2
