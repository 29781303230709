import { FormsFieldPreset, FormPlugin } from '@wix/forms-common'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import experimentsService from '../../../../../../utils/experiments-service'

export class Phone extends GeneralText {
  public get customFields() {
    return this.base.customFields
  }

  public get mainCrmTypes() {
    return [CRM_TYPES.PHONE]
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.PHONE
  }

  protected get icon() {
    return iconNames.phone
  }

  public get crmType() {
    return CRM_TYPES.PHONE
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data(): any {
    return {
      textType: 'tel',
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.contact,
        },
      },
    }
  }

  public get subRole() {
    return `subRole_${this.data.textType}`
  }

  public get subManifest() {
    return { displayName: this.translate('fields.labelName.phoneField') }
  }
}
