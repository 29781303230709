import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
  CRM_TAGS,
} from '@wix/forms-common'
import { FIELDS, COMPLEX_PHONE_ROLES } from '../../../../../../../constants/roles'
import { iconNames } from '../../../icons/types'
import { CATEGORIES } from '../../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { BaseComplexFieldWidget } from '../../base-complex-field-widget'
import { CRM_TYPES } from '../../../../../../../constants/crm-types-tags'

export class ComplexPhoneWidget extends BaseComplexFieldWidget {
  protected get icon() {
    return iconNames.phone
  }

  public get mainCrmTypes() {
    return [CRM_TYPES.PHONE]
  }

  public get crmType() {
    return CRM_TYPES.PHONE
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  public get customFields() {
    return []
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.COMPLEX_PHONE_WIDGET}`)
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_PHONE_WIDGET
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.COMPLEX_FIELD_WIDGET
  }

  public get role() {
    return FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.contact,
          isNew: true,
        },
        layout: {
          rows: 1,
          rowSpace: 1,
          colSpace: 3,
        },
      },
    }
  }

  protected get data(): any {
    return {
      name: 'complex-phone-controller',
      controllerType: 'complexPhoneController',
    }
  }

  protected get layout() {
    return {
      width: 368,
      height: 42,
    }
  }

  protected get mobileHints() {
    return {
      recommendedWidth: 260,
    }
  }

  public get base() {
    return BaseComplexFieldWidget.prototype
  }

  public get supportedInResponsive() {
    return false
  }

  protected get mainRole() {
    return COMPLEX_PHONE_ROLES.TEXT
  }
}
