import _ from 'lodash'
import translations from '../../../utils/translations'
import { ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '@wix/forms-common'
import { roleDesignMapping } from './global-design-manifest'

export const createSubmitButtonManifest = (
  { plugins }: AppStateObject = {},
  controllerType: ControllerType | 'singlePostController',
  withWidgetDesign: boolean,
) => {
  const isRegistrationForm =
    _.includes(plugins, FormPlugin.REGISTRATION_FORM) || controllerType === 'registrationForm'

  const isMultiStepForm =
    _.includes(plugins, FormPlugin.MULTI_STEP_FORM) || controllerType === 'multiStepForm'

  return {
    relatedToRole: ROLE_FORM,
    displayName: translations.t(
      `manifest.${isRegistrationForm ? 'signupButton' : 'button'}.displayName`,
    ),
    behavior: { duplicatable: false },
    gfpp: {
      desktop: {
        mainAction1: GFPP.REMOVE,
        mainAction2: {
          actionId: GFPP_IDS.SUBMIT_SETTINGS,
          label: isRegistrationForm
            ? translations.t('buttonChangeText.gfppTitle')
            : translations.t('submitSettingsPanel.formBuilder.gfppTitle'),
        },
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
          [GFPP_ACTIONS.LINK]: GFPP.REMOVE,
          [GFPP_ACTIONS.LAYOUT]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.DESIGN]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
        },
        helpId: GFPP.HELP_ID.SUBMIT_BUTTON, // FIXME - set correct helpId
      },
      mobile: {
        /* use default configurations */
      },
    },
    ...(isMultiStepForm && !withWidgetDesign
      ? { designMapping: roleDesignMapping[ROLE_SUBMIT_BUTTON] }
      : {}),
  }
}
