import _ from 'lodash'
import { TranslationFunction } from 'i18next'
import { Item } from './components/add-item/items-list/items-list'

export const BREADCRUMBS = {
  GET_STARTED: 'get started',
  CHOOSE_TYPE: 'choose type',
  ADD_ITEM: 'add item',
  CONNECT_PAYMENT: 'connect payment',
  UPGRADE: 'upgrade',
}

export const LEARN_MORE_HELP_ID = '4b31023a-1e9b-4b2f-9759-10bc78f1ae82'

export const enum PAYMENT_OPTIONS {
  SINGLE = 'single',
  LIST = 'list',
  CUSTOM = 'custom',
}

export const supportedBreadcrumbs = ({ t }: { t: TranslationFunction }) =>
  _.compact([
    {
      id: BREADCRUMBS.GET_STARTED,
      text: t('paymentWizardPanel.breadcrumbs.getStarted'),
    },
    { id: BREADCRUMBS.CHOOSE_TYPE, text: t('paymentWizardPanel.breadcrumbs.chooseType') },
    {
      id: BREADCRUMBS.ADD_ITEM,
      text: t('paymentWizardPanel.breadcrumbs.addItem'),
    },
    {
      id: BREADCRUMBS.CONNECT_PAYMENT,
      text: t('paymentWizardPanel.breadcrumbs.connectPayment'),
    },
    {
      id: BREADCRUMBS.UPGRADE,
      text: t('paymentWizardPanel.breadcrumbs.upgrade'),
    },
  ])

export const INITIAL_VALUE = ''
export const isInitialValue = (value) => value === INITIAL_VALUE
