import { FormsFieldPreset } from '@wix/forms-common'
import { ExtendApi, ExtendApiCreator } from '../extend-api'
import translations from '../../../../utils/translations'
import CoreApi from '../../core-api'
import { EVENTS } from '../../../../constants/bi'
import { ACTION_TYPE } from '../action-types'
import { CUSTOM_ACTION_CLICKED } from '../event-types'
import _ from 'lodash'
class SubscribersApi extends ExtendApiCreator {
  private coreApi: CoreApi

  constructor(coreApi: CoreApi) {
    super()
    this.coreApi = coreApi
  }

  customActionClickedHandler = (eventPayload) => {
    const { action } = eventPayload

    switch (action.panelName) {
      case 'manageSubscribersPanel':
        this.coreApi.log({
          evid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL,
          action: 'Manage Subscribers',
        })
        break
    }
  }

  get events(): { [event: string]: (payload: any) => any } {
    return {
      [CUSTOM_ACTION_CLICKED]: this.customActionClickedHandler,
    }
  }

  getNewFields = () => {
    return {
      remove: [FormsFieldPreset.GENERAL_SUBSCRIBE],
    }
  }

  getExtendApi(): ExtendApi {
    return {
      onEvent: this.createOnEvent(),
      'fields.getNewFields': this.getNewFields,
      appConfig: () => ({
        features: {
          doubleOptIn: {
            withoutOptInField: true,
          },
        },
        mainCTA: {
          description: translations.t('formSettings.mainTab.getSubscribers.mainActionContent'),
          actions: [
            {
              type: ACTION_TYPE.OPEN_MODAL_PANEL,
              panelName: 'manageSubscribersPanel',
              text: translations.t('formSettings.mainTab.getSubscribers.mainActionButtonText'),
            },
          ],
        },
      }),
    }
  }
}

export default SubscribersApi
