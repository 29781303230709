import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
  BASE_DESIGN_GROUPS,
  FieldRenderConfigType,
} from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class GeneralTimePicker extends IGeneralField {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_TIME_PICKER
  }

  protected get icon() {
    return iconNames.time
  }

  public get collectionFieldType() {
    return FieldCollectionType.TIME
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.TIME_PICKER
  }

  public get role() {
    return FIELDS.ROLE_FIELD_TIME_PICKER
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
        addFieldPanelData: { 
          category: CATEGORIES.date,
          subCategory: CATEGORIES.basic,
        }
      },
    }
  }

  public get base() {
    return GeneralTimePicker.prototype
  }

  public get manifest() {
    return fieldManifest({
      displayName: this.translate('fields.labelName.timePicker'),
      duplicatable: true,
    })
  }

  public get supportsLabel() {
    return true
  }

  public get revokedCommonLayoutTransformationProps() {
    return ['alignment']
  }

  protected get skin() {
    return 'wixui.skins.TimePicker'
  }

  protected get data() {
    return {
      type: 'TimePickerData',
      value: '14:30:00.000',
      step: 15,
      placeholder: 'Choose a time',
    }
  }

  protected get props() {
    return {
      type: 'TimePickerProperties',
      alignment: 'center',
      useAmPmFormat: false,
      controller: 'text',
      initialTime: 'value',
      margin: 15,
      labelMargin: 14,
      labelPadding: 0,
    }
  }

  protected get layout() {
    return {
      width: 142,
      height: 50,
    }
  }

  protected get additionalData() {
    return {
      mobileHints: {
        type: 'MobileHints',
        recommendedScale: 1,
        recommendedWidth: 280,
        recommendedHeight: 40,
      },
    }
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['backgroundColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['borderColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES]: ['borderWidth'],
        [BASE_DESIGN_GROUPS.INPUT_CORNERS]: ['borderRadius'],
        [BASE_DESIGN_GROUPS.INPUT_SHADOW]: ['boxShadow'],
        [BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]: ['textFont'],
        [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]: ['textColor'],
        [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['placeholderTextColor'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['hoverBackgroundColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['hoverBorderColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: ['hoverBorderWidth'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['focusBackgroundColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['focusBorderColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: ['focusBorderWidth'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['errorBackgroundColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['errorBorderColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: ['errorBorderWidth'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txtlbl'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fntlbl'],
        [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
      },
    }
  }

  public get layoutComponentProps() {
    return ['alignment', 'labelMargin', 'labelPadding', 'margin']
  }

  protected get styleProperties() {
    return {
      groups: {},
      properties: {
        backgroundColor: '#FFFFFF',
        borderColor: '#919191',
        borderRadius: '0px',
        borderWidth: '2',
        boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'boxShadowToggleOn-boxShadow': 'false',
        disabledBackgroundColor: '#FFFFFF',
        disabledBorderColor: '#DBDBDB',
        disabledBorderWidth: '1px',
        disabledTextColor: '#D4D4D4',
        errorBackgroundColor: '#FFFFFF',
        errorBorderColor: '#F64D43',
        errorBorderWidth: '1px',
        focusBackgroundColor: '#FFFFFF',
        focusBorderColor: '#000000',
        focusBorderWidth: '2',
        hoverBackgroundColor: '#F2FAF9',
        hoverBorderColor: '#000000',
        hoverBorderWidth: '2',
        iconColor: '#000000',
        placeholderTextColor: '#525252',
        textColor: '#000000',
        textFont: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
      },
      propertiesSource: {
        backgroundColor: 'value',
        borderColor: 'value',
        borderRadius: 'value',
        borderWidth: 'value',
        boxShadow: 'value',
        'boxShadowToggleOn-boxShadow': 'value',
        disabledBackgroundColor: 'value',
        disabledBorderColor: 'value',
        disabledBorderWidth: 'value',
        disabledTextColor: 'value',
        errorBackgroundColor: 'value',
        errorBorderColor: 'value',
        errorBorderWidth: 'value',
        focusBackgroundColor: 'value',
        focusBorderColor: 'value',
        focusBorderWidth: 'value',
        hoverBackgroundColor: 'value',
        hoverBorderColor: 'value',
        hoverBorderWidth: 'value',
        iconColor: 'value',
        placeholderTextColor: 'value',
        textColor: 'value',
        textFont: 'value',
      },
    }
  }
}
