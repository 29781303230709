import { getCountryCodeByGEO } from './utils'

export const COUNTRIES_CODES = {
  AW: '+297',
  AO: '+244',
  AF: '+93',
  AI: '+1',
  AX: '+358',
  AL: '+355',
  AD: '+376',
  AE: '+971',
  AR: '+54',
  AM: '+374',
  AS: '+1684',
  AQ: '+672',
  TF: '+262',
  AG: '+1',
  AU: '+61',
  AT: '+43',
  AZ: '+994',
  BI: '+257',
  BE: '+32',
  BJ: '+229',
  BQ: '+599',
  BF: '+226',
  BD: '+880',
  BG: '+359',
  BH: '+973',
  BS: '+1',
  BA: '+387',
  BL: '+590',
  BY: '+375',
  BZ: '+501',
  BM: '+1',
  BO: '+591',
  BR: '+55',
  BB: '+1246',
  BN: '+673',
  BT: '+975',
  BV: '+55',
  BW: '+267',
  CF: '+236',
  CA: '+1',
  CC: '+61',
  CH: '+41',
  CL: '+56',
  CN: '+86',
  CI: '+225',
  CM: '+237',
  CD: '+243',
  CG: '+242',
  CK: '+682',
  CO: '+57',
  KM: '+269',
  CV: '+238',
  CR: '+506',
  CU: '+53',
  CW: '+599',
  CX: '+61',
  KY: '+1',
  CY: '+357',
  CZ: '+420',
  DE: '+49',
  DJ: '+253',
  DM: '+1',
  DK: '+45',
  DO: '+1',
  DZ: '+213',
  EC: '+593',
  EG: '+20',
  ER: '+291',
  EH: '+212',
  ES: '+34',
  EE: '+372',
  ET: '+251',
  FI: '+358',
  FJ: '+679',
  FK: '+500',
  FR: '+33',
  FO: '+298',
  FM: '+691',
  GA: '+241',
  GB: '+44',
  GE: '+995',
  GG: '+44',
  GH: '+233',
  GI: '+350',
  GN: '+224',
  GP: '+590',
  GM: '+220',
  GW: '+245',
  GQ: '+240',
  GR: '+30',
  GD: '+1',
  GL: '+299',
  GT: '+502',
  GF: '+594',
  GU: '+1',
  GY: '+592',
  HK: '+852',
  HM: '+672',
  HN: '+504',
  HR: '+385',
  HT: '+509',
  HU: '+36',
  ID: '+62',
  IM: '+44',
  IN: '+91',
  IO: '+246',
  IE: '+353',
  IR: '+98',
  IS: '+354',
  IL: '+972',
  IT: '+39',
  JM: '+1876',
  JE: '+44',
  JO: '+962',
  JP: '+81',
  KZ: '+7',
  KE: '+254',
  KG: '+996',
  KH: '+855',
  KI: '+686',
  KN: '+1',
  KR: '+82',
  XK: '+383',
  KW: '+965',
  LA: '+856',
  LB: '+961',
  LR: '+231',
  LY: '+218',
  LC: '+1',
  LI: '+423',
  LK: '+94',
  LS: '+266',
  LT: '+370',
  LU: '+352',
  LV: '+371',
  MO: '+853',
  MF: '+590',
  MA: '+212',
  MC: '+377',
  MD: '+373',
  MG: '+261',
  MV: '+960',
  MX: '+52',
  MH: '+692',
  MK: '+389',
  ML: '+223',
  MT: '+356',
  MM: '+95',
  ME: '+382',
  MN: '+976',
  MP: '+1670',
  MZ: '+258',
  MR: '+222',
  MS: '+1',
  MQ: '+596',
  MU: '+230',
  MW: '+265',
  MY: '+60',
  YT: '+262',
  NA: '+264',
  NC: '+687',
  NE: '+227',
  NF: '+672',
  NG: '+234',
  NI: '+505',
  NU: '+683',
  NL: '+31',
  NO: '+47',
  NP: '+977',
  NR: '+674',
  NZ: '+64',
  OM: '+968',
  PK: '+92',
  PA: '+507',
  PN: '+64',
  PE: '+51',
  PH: '+63',
  PW: '+680',
  PG: '+675',
  PL: '+48',
  PR: '+1',
  KP: '+850',
  PT: '+351',
  PY: '+595',
  PS: '+970',
  PF: '+689',
  QA: '+974',
  RE: '+262',
  RO: '+40',
  RU: '+7',
  RW: '+250',
  SA: '+966',
  SD: '+249',
  SN: '+221',
  SG: '+65',
  GS: '+500',
  SH: '+290',
  SJ: '+47',
  SB: '+677',
  SL: '+232',
  SV: '+503',
  SM: '+378',
  SO: '+252',
  PM: '+508',
  RS: '+381',
  SS: '+211',
  ST: '+239',
  SR: '+597',
  SK: '+421',
  SI: '+386',
  SE: '+46',
  SZ: '+268',
  SX: '+1721',
  SC: '+248',
  SY: '+963',
  TC: '+1',
  TD: '+235',
  TG: '+228',
  TH: '+66',
  TJ: '+992',
  TK: '+690',
  TM: '+993',
  TL: '+670',
  TO: '+676',
  TT: '+1868',
  TN: '+216',
  TR: '+90',
  TV: '+688',
  TW: '+886',
  TZ: '+255',
  UG: '+256',
  UA: '+380',
  UM: '+1',
  UY: '+598',
  US: '+1',
  UZ: '+998',
  VA: '+39',
  VC: '+1',
  VE: '+58',
  VG: '+1',
  VI: '+1',
  VN: '+84',
  VU: '+678',
  WF: '+681',
  WS: '+685',
  YE: '+967',
  ZA: '+27',
  ZM: '+260',
  ZW: '+263',
}

export const GEO_CODE_MAP = {
  ABW: 'AW',
  AGO: 'AO',
  AFG: 'AF',
  AIA: 'AI',
  ALA: 'AX',
  ALB: 'AL',
  AND: 'AD',
  ARE: 'AE',
  ARG: 'AR',
  ARM: 'AM',
  ASM: 'AS',
  ATA: 'AQ',
  ATF: 'TF',
  ATG: 'AG',
  AUS: 'AU',
  AUT: 'AT',
  AZE: 'AZ',
  BDI: 'BI',
  BEL: 'BE',
  BEN: 'BJ',
  BES: 'BQ',
  BFA: 'BF',
  BGD: 'BD',
  BGR: 'BG',
  BHR: 'BH',
  BHS: 'BS',
  BIH: 'BA',
  BLM: 'BL',
  BLR: 'BY',
  BLZ: 'BZ',
  BMU: 'BM',
  BOL: 'BO',
  BRA: 'BR',
  BRB: 'BB',
  BRN: 'BN',
  BTN: 'BT',
  BVT: 'BV',
  BWA: 'BW',
  CAF: 'CF',
  CAN: 'CA',
  CCK: 'CC',
  CHE: 'CH',
  CHL: 'CL',
  CHN: 'CN',
  CIV: 'CI',
  CMR: 'CM',
  COD: 'CD',
  COG: 'CG',
  COK: 'CK',
  COL: 'CO',
  COM: 'KM',
  CPV: 'CV',
  CRI: 'CR',
  CUB: 'CU',
  CUW: 'CW',
  CXR: 'CX',
  CYM: 'KY',
  CYP: 'CY',
  CZE: 'CZ',
  DEU: 'DE',
  DJI: 'DJ',
  DMA: 'DM',
  DNK: 'DK',
  DOM: 'DO',
  DZA: 'DZ',
  ECU: 'EC',
  EGY: 'EG',
  ERI: 'ER',
  ESH: 'EH',
  ESP: 'ES',
  EST: 'EE',
  ETH: 'ET',
  FIN: 'FI',
  FJI: 'FJ',
  FLK: 'FK',
  FRA: 'FR',
  FRO: 'FO',
  FSM: 'FM',
  GAB: 'GA',
  GBR: 'GB',
  GEO: 'GE',
  GGY: 'GG',
  GHA: 'GH',
  GIB: 'GI',
  GIN: 'GN',
  GLP: 'GP',
  GMB: 'GM',
  GNB: 'GW',
  GNQ: 'GQ',
  GRC: 'GR',
  GRD: 'GD',
  GRL: 'GL',
  GTM: 'GT',
  GUF: 'GF',
  GUM: 'GU',
  GUY: 'GY',
  HKG: 'HK',
  HMD: 'HM',
  HND: 'HN',
  HRV: 'HR',
  HTI: 'HT',
  HUN: 'HU',
  IDN: 'ID',
  IMN: 'IM',
  IND: 'IN',
  IOT: 'IO',
  IRL: 'IE',
  IRN: 'IR',
  ISL: 'IS',
  ISR: 'IL',
  ITA: 'IT',
  JAM: 'JM',
  JEY: 'JE',
  JOR: 'JO',
  JPN: 'JP',
  KAZ: 'KZ',
  KEN: 'KE',
  KGZ: 'KG',
  KHM: 'KH',
  KIR: 'KI',
  KNA: 'KN',
  KOR: 'KR',
  KOS: 'XK',
  KWT: 'KW',
  LAO: 'LA',
  LBN: 'LB',
  LBR: 'LR',
  LBY: 'LY',
  LCA: 'LC',
  LIE: 'LI',
  LKA: 'LK',
  LSO: 'LS',
  LTU: 'LT',
  LUX: 'LU',
  LVA: 'LV',
  MAC: 'MO',
  MAF: 'MF',
  MAR: 'MA',
  MCO: 'MC',
  MDA: 'MD',
  MDG: 'MG',
  MDV: 'MV',
  MEX: 'MX',
  MHL: 'MH',
  MKD: 'MK',
  MLI: 'ML',
  MLT: 'MT',
  MMR: 'MM',
  MNE: 'ME',
  MNG: 'MN',
  MNP: 'MP',
  MOZ: 'MZ',
  MRT: 'MR',
  MSR: 'MS',
  MTQ: 'MQ',
  MUS: 'MU',
  MWI: 'MW',
  MYS: 'MY',
  MYT: 'YT',
  NAM: 'NA',
  NCL: 'NC',
  NER: 'NE',
  NFK: 'NF',
  NGA: 'NG',
  NIC: 'NI',
  NIU: 'NU',
  NLD: 'NL',
  NOR: 'NO',
  NPL: 'NP',
  NRU: 'NR',
  NZL: 'NZ',
  OMN: 'OM',
  PAK: 'PK',
  PAN: 'PA',
  PCN: 'PN',
  PER: 'PE',
  PHL: 'PH',
  PLW: 'PW',
  PNG: 'PG',
  POL: 'PL',
  PRI: 'PR',
  PRK: 'KP',
  PRT: 'PT',
  PRY: 'PY',
  PSE: 'PS',
  PYF: 'PF',
  QAT: 'QA',
  REU: 'RE',
  ROU: 'RO',
  RUS: 'RU',
  RWA: 'RW',
  SAU: 'SA',
  SDN: 'SD',
  SEN: 'SN',
  SGP: 'SG',
  SGS: 'GS',
  SHN: 'SH',
  SJM: 'SJ',
  SLB: 'SB',
  SLE: 'SL',
  SLV: 'SV',
  SMR: 'SM',
  SOM: 'SO',
  SPM: 'PM',
  SRB: 'RS',
  SSD: 'SS',
  STP: 'ST',
  SUR: 'SR',
  SVK: 'SK',
  SVN: 'SI',
  SWE: 'SE',
  SWZ: 'SZ',
  SXM: 'SX',
  SYC: 'SC',
  SYR: 'SY',
  TCA: 'TC',
  TCD: 'TD',
  TGO: 'TG',
  THA: 'TH',
  TJK: 'TJ',
  TKL: 'TK',
  TKM: 'TM',
  TLS: 'TL',
  TON: 'TO',
  TTO: 'TT',
  TUN: 'TN',
  TUR: 'TR',
  TUV: 'TV',
  TWN: 'TW',
  TZA: 'TZ',
  UGA: 'UG',
  UKR: 'UA',
  UMI: 'UM',
  URY: 'UY',
  USA: 'US',
  UZB: 'UZ',
  VAT: 'VA',
  VCT: 'VC',
  VEN: 'VE',
  VGB: 'VG',
  VIR: 'VI',
  VNM: 'VN',
  VUT: 'VU',
  WLF: 'WF',
  WSM: 'WS',
  YEM: 'YE',
  ZAF: 'ZA',
  ZMB: 'ZM',
  ZWE: 'ZW',
}

export const DEFAULT_COUNTRY_CODE = getCountryCodeByGEO('US')
/**
 * The ITU says the maximum length should be 15, but there are longer
 * numbers in Germany.
 */
export const DEFAULT_PHONE_NUMBER_VALIDATION_REGEX = '^([(][0-9]{1,3}[)][-]?)?([0-9][-]?){3,16}[0-9]$'

export type CountriesCodesKeys = keyof typeof COUNTRIES_CODES
