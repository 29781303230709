import _ from 'lodash'
import { SyncField, SyncData } from './constants/types'
import { createSuffixedName } from '../../utils/utils'
import { NEW_FIELD_ID } from './constants'
import { CustomFieldResponse } from '../../constants/field-types'
import { crmTypesTags, CUSTOM_FIELD } from '../../constants/crm-types-tags'
import { fieldsStore } from '../../editor-app/core/preset/fields/fields-store'

export const generateNewCustomFieldId = (existingCustomFields: CustomFieldResponse[]) =>
  createSuffixedName(
    existingCustomFields.map(({ id }) => id),
    NEW_FIELD_ID,
    '_',
  )

export const isNewCustomFieldId = (id: string) => id && id.startsWith(NEW_FIELD_ID)

export const isMainCrmTypeSupportedInField = (crmType: string, field: SyncField): boolean => {
  const fieldPreset = fieldsStore.get(field.fieldType)
  return _.includes(fieldPreset.mainCrmTypes, crmType)
}

export const isCustomFieldSupportedInField = (
  customField: CustomFieldResponse,
  field: SyncField,
) => {
  return _.includes(field.customFields, customField.fieldType)
}

export const isCrmTypeAllowedOnce = (crmType: string) =>
  !_.eq(crmType, CUSTOM_FIELD) && !crmTypesTags[crmType]

export const areSyncDataEqual = (syncData1: SyncData, syncData2: SyncData) => {
  return syncData1.crmType !== syncData2.crmType
    ? false
    : syncData1.crmType === CUSTOM_FIELD
    ? syncData1.customFieldId === syncData2.customFieldId &&
      syncData1.customFieldName === syncData2.customFieldName
    : syncData1.crmTag === syncData2.crmTag
}
