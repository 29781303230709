import _ from 'lodash'
import { FormPlugin, FormsFieldPreset } from '@wix/forms-common'
import { FormPreset } from '../../../constants/form-types'
import successMessageStructure from '../../../assets/presets/hidden-message.json'
import translations from '../../../utils/translations'
import {
  handleAfterSubmitBehavior,
  hideFormInMobile,
  ContactFormEnhancer,
  createBaseStructure,
  emailEnhancer,
  behaviorsEnhancer,
  createSubmitButtonComponent,
  updateConfig,
  updateRequired,
  updateTextAlignment,
  ContactFormFieldEnhancer,
  updateCrmLabel,
  fixDuplicatedCrmLabels,
  enhanceWithStyle,
  getElementWithPadding,
  wrapEnhancerWithRoles,
} from './contact-form-service'
import {
  COMPLEX_CONTAINER_ROLES,
  COMPLEX_PHONE_ROLES,
  FIELDS,
  ROLE_TITLE,
} from '../../../constants/roles'
import { fieldsStore } from '../preset/fields/fields-store'
import { enhanceFormWithMobileStructure } from './contact-form-mobile-service'
import { enhanceFormWithDesktopStructure } from './contact-form-layout'
import envelop from '../../../assets/presets/subscribe-form-envelop.json'
import waves from '../../../assets/presets/subscribe-form-waves.json'
import { createComplexField } from './form-service'

const createSuccessMessageComponent = (contactForm: ContactForm): RawComponentStructure =>
  _.merge({}, successMessageStructure, {
    layout: {
      width: contactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 102,
    },
    data: {
      text: `<p class="font_8"><span style="color:#8FCA8F;">${
        contactForm.data.successMessage ||
        translations.t('subscribeContactForm.defaultSuccessMessage')
      }</span></p>`,
    },
  })

const skinToComponents = {
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon': [
    envelop,
  ],
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope': [waves],
}

export const convertSubscribeContactFormToWixForms = (
  subscribeContactForm: SubscribeContactForm,
  fontOptions,
  colorMap: ColorPalette,
  { mobileLayouts, desktopLayouts, payload }: { mobileLayouts; desktopLayouts; payload? },
): RawComponentStructure => {
  const titleComponent: RawComponentStructure = _.merge({}, successMessageStructure, {
    layout: {
      width: subscribeContactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 0,
    },
    data: {
      text: `<h1 class="font_5"><span style="color:#000000;">${subscribeContactForm.data.subscribeFormTitle}</span></h1>`,
    },
    role: ROLE_TITLE,
  })
  const fields = fetchFields(subscribeContactForm, payload)
  const buttonPadding =
    getElementWithPadding(desktopLayouts) || getElementWithPadding(mobileLayouts)
  const components: RawComponentStructure[] = [
    ...(skinToComponents[subscribeContactForm.skin] || []),
    titleComponent,
    ...fields,
    createSubmitButtonComponent(subscribeContactForm, !!buttonPadding),
    createSuccessMessageComponent(subscribeContactForm),
  ]
  const baseStructure = _.merge({}, createBaseStructure(subscribeContactForm), {
    components,
    config: {
      preset: FormPreset.GET_SUBSCRIBERS,
      formName: translations.t('subscribeContactForm.defaultFormName'),
      plugins: [
        {
          id: FormPlugin.GET_SUBSCRIBERS,
        },
      ],
    },
  })
  const enhancers: ContactFormEnhancer[] = [
    handleAfterSubmitBehavior,
    hideFormInMobile,
    enhanceWithStyle,
    emailEnhancer,
    behaviorsEnhancer,
    enhanceFormWithMobileStructure,
    enhanceFormWithDesktopStructure,
    fixDuplicatedCrmLabels,
    fixLayoutEnvelopForm,
  ]
  const convertedStructure = enhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) =>
      enhancer(
        subscribeContactForm,
        previousStructure,
        { mobileLayouts, desktopLayouts, payload },
        fontOptions,
        colorMap,
      ),
    baseStructure,
  )
  return convertedStructure
}

const fixLayoutEnvelopForm: ContactFormEnhancer = (
  contactForm: ContactForm,
  convertedForm: RawComponentStructure,
) => {
  if (
    contactForm.skin ===
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope'
  ) {
    const convertLayout = (layout: FieldLayout) => ({
      width: layout.width - 30,
      height: layout.height - 30,
    })

    const components = convertedForm.components
    if (contactForm.data.onSubmitBehavior === 'message') {
      const message = _.last(convertedForm.components)
      components[components.length - 1] = _.merge({}, message, {
        layout: { width: message.layout.width - 45, x: 15 },
        ...(message.mobileStructure
          ? {
              mobileStructure: {
                layout: {
                  width: message.mobileStructure.layout.width - 45,
                  x: 15,
                },
              },
            }
          : {}),
      })
    }

    const formWithNewLayout = _.merge({}, convertedForm, {
      layout: convertLayout(convertedForm.layout),
      ...(convertedForm.mobileStructure
        ? {
            mobileStructure: {
              layout: convertLayout(convertedForm.mobileStructure.layout),
            },
          }
        : {}),
      components,
    })

    return <RawComponentStructure>formWithNewLayout
  }
  return convertedForm
}

const paddingEnhancer: ContactFormFieldEnhancer = (
  contactForm,
  _field,
  convertedField,
  _payload,
  skipRoles,
) => {
  const action = (field: Partial<RawComponentStructure>) => {
    if (
      contactForm.skin ===
      'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin'
    ) {
      return _.merge({}, field, { props: { textPadding: 5 } }) as RawComponentStructure
    }
    return _.merge({}, field, { props: { textPadding: 7 } }) as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

const updatePlaceholder: ContactFormFieldEnhancer = (
  _contactForm: ContactForm,
  dynamicField: ContactFormField,
  convertedField: RawComponentStructure,
  _payload,
  skipRoles: string[],
): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.data.label = ''
    field.data.placeholder = dynamicField.displayLabel
    field.props.placeholder = dynamicField.displayLabel
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

const updateComboboxValue: ContactFormFieldEnhancer = (
  _contactForm: ContactForm,
  _dynamicField: ContactFormField,
  convertedField: RawComponentStructure,
  _payload,
  skipRoles: string[],
): RawComponentStructure => {
  const action = (field: Partial<RawComponentStructure>) => {
    field.data.value = 'US +1'
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skipRoles, action)
}

const fieldEnhancers: ContactFormFieldEnhancer[] = [
  updateConfig,
  updateCrmLabel,
  updatePlaceholder,
  updateRequired,
  updateTextAlignment,
  paddingEnhancer,
]

const createBaseField = (subscribeContactFormField: ContactFormField) => {
  switch (subscribeContactFormField.name) {
    case 'FirstNameField':
      return fieldsStore.get(FormsFieldPreset.FIRST_NAME)
    case 'LastNameField':
      return fieldsStore.get(FormsFieldPreset.LAST_NAME)
    case 'EmailField':
      return fieldsStore.get(FormsFieldPreset.MAIN_EMAIL)
    default:
      return null
  }
}

const getSubscribeFormFields = (skin) => {
  switch (skin) {
    case 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin':
      return ['hiddenFirstNameField', 'hiddenLastNameField', 'hiddenEmailField', 'hiddenPhoneField']
    case 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat':
      return ['hiddenEmailField', 'hiddenPhoneField', 'hiddenFirstNameField', 'hiddenLastNameField']
    case 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon':
      return ['hiddenEmailField', 'hiddenPhoneField', 'hiddenFirstNameField', 'hiddenLastNameField']
    default:
      return ['hiddenFirstNameField', 'hiddenLastNameField', 'hiddenPhoneField', 'hiddenEmailField']
  }
}

const enhancePhone = (
  contactForm: SubscribeContactForm,
  baseField,
  field: ContactFormField,
  payload,
): RawComponentStructure => {
  const bindIgnoredRoles = (fn, skipRoles) => {
    return function (...args) {
      return fn(...args.slice(0, args.length), skipRoles)
    }
  }
  const enhancers = [
    bindIgnoredRoles(updatePlaceholder, [
      COMPLEX_PHONE_ROLES.DROPDOWN,
      COMPLEX_CONTAINER_ROLES.PHONE,
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
    ]),
    bindIgnoredRoles(updateRequired, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateTextAlignment, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(paddingEnhancer, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateConfig, []),
    bindIgnoredRoles(updateCrmLabel, [
      COMPLEX_PHONE_ROLES.DROPDOWN,
      COMPLEX_PHONE_ROLES.TEXT,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
    bindIgnoredRoles(updateComboboxValue, [
      FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
      COMPLEX_PHONE_ROLES.TEXT,
      COMPLEX_CONTAINER_ROLES.PHONE,
    ]),
  ]
  return enhancers.reduce<RawComponentStructure>(
    (previousFieldStructure, enhancer) =>
      enhancer(contactForm, field, previousFieldStructure, payload),
    baseField,
  )
}

const fetchFields = (contactForm: SubscribeContactForm, payload): RawComponentStructure[] =>
  getSubscribeFormFields(contactForm.skin).reduce((acc, fieldKey) => {
    if (contactForm.props[fieldKey]) {
      const fieldName = _.replace(fieldKey, 'hidden', '')
      const field: ContactFormField = {
        name: fieldName,
        required: contactForm.props[`required${fieldName}`],
        displayLabel: contactForm.data[`${_.lowerFirst(fieldName)}Label`],
      }
      let baseField: RawComponentStructure, convertedFieldStructure: RawComponentStructure
      if (field.name === 'PhoneField') {
        const complexPhoneBase = createComplexField({
          sameComplexFieldsOnStage: [],
          formConfig: {},
          fieldComponent: baseField,
          extraData: {},
          fieldType: FormsFieldPreset.COMPLEX_PHONE_WIDGET,
          preset: FormPreset.GET_SUBSCRIBERS,
          commonStyles: {},
          fieldsData: [],
          plugins: [FormPlugin.GET_SUBSCRIBERS],
          controllerId: 'placeholder-id',
        })
        baseField = { ...complexPhoneBase, ...complexPhoneBase.data }
        convertedFieldStructure = enhancePhone(contactForm, baseField, field, payload)
      } else {
        baseField = createBaseField(field).fieldStructure() as RawComponentStructure
        convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
          (previousFieldStructure, enhancer) =>
            enhancer(contactForm, field, previousFieldStructure, payload, []),
          baseField,
        )
      }
      return [...acc, convertedFieldStructure]
    }
    return acc
  }, [])
