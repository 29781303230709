import _ from 'lodash'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'
import {
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
  FieldRenderConfigType,
} from '@wix/forms-common'
import { IGeneralField } from './general-field'
import { CATEGORIES } from '../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldsStore } from './fields-store'
import { GFPP_ACTIONS, GFPP_IDS } from '../../manifests/manifest-commons'
import { getPluginAppState } from '../../plugins/pluginAppStates'

type ExtraData = {
  role: string
  subRole?: string
  connectionConfig: {
    crmType: string
    crmLabel?: string
    crmTag?: string
    customFieldId?: string
    label?: string
  }
  props: any
  data: any
  layout: any
  layoutResponsive: ResponsiveLayout
}

export type FieldExtraData = Partial<ExtraData>
export type ComplexFieldExtraData = Partial<{ [key in FieldPreset]: FieldExtraData }>

export interface FieldProperties {
  collectionFieldType?: FieldCollectionType | false
  componentType: FIELD_COMPONENT_TYPES
  extraData: FieldExtraData
}

export interface FieldRenderConfigStructure {
  isMandatory?: boolean
  crmSync?: FieldRenderConfigType
  connectPanelRegistrationFooter?: FieldRenderConfigType
  connectPanelFieldNameSection?: FieldRenderConfigType
  required?: FieldRenderConfigType
  internalName?: FieldRenderConfigType
  checkedByDefault?: FieldRenderConfigType
  isEditable?: boolean
  addFieldPanelData?: AddFieldPanelData
  layout?: ContainerLayoutMetaData | ChildLayoutMetaData
}

export interface AddFieldPanelData {
  category: string
  isPremium?: boolean
  isNew?: boolean
  dependsOn?: string[]
  hideTranslationPostfix?: boolean
  showTooltip?: boolean
  subCategory?: string
}

export interface ContainerLayoutMetaData {
  rows: number
  rowSpace: number // percentage
  colSpace: number // percentage
}

export interface ChildLayoutMetaData {
  row: number
  col: number
  width: number // percentage
}

export type FieldRenderConfig = { [key in FormPlugin]?: FieldRenderConfigStructure }

// TODO: Merge this with default form builder config render and merge unused flows (AKA === required.ENABLED/REMOVE? why both?)
const DEFAULT_FIELD_RENDER_CONFIG: FieldRenderConfigStructure = {
  isEditable: true,
  isMandatory: false,
  crmSync: FieldRenderConfigType.ENABLED,
  connectPanelRegistrationFooter: FieldRenderConfigType.REMOVE,
  connectPanelFieldNameSection: FieldRenderConfigType.ENABLED,
  required: FieldRenderConfigType.ENABLED,
  internalName: FieldRenderConfigType.ENABLED,
  checkedByDefault: FieldRenderConfigType.ENABLED,
  addFieldPanelData: { category: CATEGORIES.none },
}

export type PluginsFieldExtraData = { [key in FormPlugin]?: FieldExtraData }

export const getFieldRenderConfigFields = (
  plugins: FormPlugin[],
  fieldType: FieldPreset,
  defaults = {},
): FieldRenderConfigStructure => {
  const config = fieldsStore.get(fieldType).metadata.renderConfig

  // current implementation support only 1 effected plugin per preset, change this when we will have more than 1
  const plugin = getPluginAppState(plugins).main
  let basePluginConfig = {}

  if (config && config[FormPlugin.FORM_BUILDER]) {
    basePluginConfig = config[FormPlugin.FORM_BUILDER]
  }

  if (config && plugin && config[plugin]) {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, config[plugin])
  } else {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, defaults)
  }
}

export const getFieldPluginsExtraData = ({
  fieldType,
  plugins,
}: {
  fieldType: FieldPreset
  plugins: FormPlugin[]
}): FieldExtraData => {
  const pluginsExtraData = fieldsStore.get(fieldType).metadata.pluginsExtraData

  let extraData = {}

  if (!pluginsExtraData) {
    return extraData
  }

  _.forEach(plugins, (plugin) => {
    const pluginExtraData = _.get(pluginsExtraData, plugin)

    if (pluginExtraData) {
      _.merge(extraData, pluginExtraData)
    }
  })

  return extraData
}

export const allowCollectionSync = (fieldType: FieldPreset): boolean => {
  return !!!fieldsStore.get(fieldType).metadata.disableCollectionSync
}

export const createFieldsManifests = ({ withWidgetDesign }) =>
  _.chain(fieldsStore.all())
    .values()
    .reduce((acc, field: IGeneralField) => {
      if (!acc[field.role]) {
        const updatedManifest = withWidgetDesign
          ? field.manifest
          : { ...field.manifest, designMapping: field.designMapping }

        const iconButtons = _.get(updatedManifest, 'gfpp.desktop.iconButtons', {})
        if (iconButtons[GFPP_ACTIONS.CUSTOM_CONNECT]) {
          iconButtons[GFPP_ACTIONS.SETTINGS] = { actionId: GFPP_IDS.CONNECT_FIELD }
          delete iconButtons[GFPP_ACTIONS.CUSTOM_CONNECT]
        }

        acc[field.role] = updatedManifest
      }
      if (field.subRole && !acc[field.subRole]) {
        acc[field.subRole] = field.subManifest
      }
      return acc
    }, {})
    .value()
