import {
  FormsFieldPreset,
  FIELD_COMPONENT_TYPES,
  FormPlugin,
  FieldCollectionType,
} from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { CUSTOM_FIELD } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'
import { baseDesignMappingValues } from '../../constants'

export class GeneralTextBox extends IGeneralField {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_TEXT_BOX
  }

  protected get icon() {
    return iconNames.paragraph
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.TEXT_AREA_INPUT
  }

  public get role() {
    return FIELDS.ROLE_FIELD_TEXT_AREA
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  protected get skin() {
    return 'TextAreaDefaultSkin'
  }

  protected get responsiveSkin() {
    return 'wysiwyg.viewer.skins.input.ResponsiveTextAreaDefaultSkin'
  }

  protected get layout() {
    return {
      width: 510,
      height: 126,
    }
  }

  protected get props() {
    return {
      type: 'TextAreaInputProperties',
      textAlignment: 'left',
      textPadding: 16,
      defaultTextType: 'placeholderText',
      inputHeight: 126,
    }
  }

  protected get data(): any {
    return {
      type: 'TextAreaInput',
      value: '',
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: {
          category: CATEGORIES.text,
          subCategory: CATEGORIES.basic,
        },
      },
    }
  }

  protected get pluginsExtraData() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        layout: {
          width: 240,
        },
      },
    }
  }

  public get base() {
    return GeneralTextBox.prototype
  }

  public get manifest() {
    return fieldManifest({
      displayName: this.translate('fields.labelName.paragraph'),
      duplicatable: true,
    })
  }

  public get designMapping() {
    return {
      [this.componentType]: baseDesignMappingValues,
    }
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return true
  }

  public get layoutComponentProps() {
    return ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin']
  }

  protected get styleProperties() {
    return {
      properties: {
        'alpha-bg': '1',
        'alpha-bgd': '1',
        'alpha-bge': '1',
        'alpha-bgf': '1',
        'alpha-bgh': '1',
        'alpha-brd': '0.55',
        'alpha-brde': '0.6',
        'alpha-brdf': '1',
        'alpha-brdh': '0.7',
        bg: 'rgba(255, 255, 255, 1)',
        bg2: '#aaaaaa',
        bgd: '#FFFFFF',
        bgf: 'color_11',
        bgh: 'color_11',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brdd: '#DBDBDB',
        brde: '#f60419',
        brdf: 'color_18',
        brdh: 'color_15',
        brw: '1',
        brwd: '0',
        brwf: '1',
        brwh: '1',
        fnt: 'font_8',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        txt: 'color_15',
        txt2: 'color_14',
        txtd: '#DBDBDB',
      },
    }
  }
}
